import React, { useEffect } from 'react';
import * as d3 from 'd3';

// STYLES
import './OldInteractiveAboutSection.css';

const OldInteractiveAboutSection = () => {
  useEffect(() => {
    let svg = d3.select('svg'),
      width = +svg.style('width').replace('px', ''),
      height = +svg.style('height').replace('px', ''),
      radius = 70,
      xGoal = 0.7 * width;

    if (0.3 * width < 2 * radius) {
      radius = 50;
    }

    let minX = 0;
    let maxX = width - radius;
    let minY = 0;
    let maxY = height - radius / 2;

    let circles = [
      {
        name1: 'professional',
        name2: 'philosophy',
        description:
          'I am a believer in finding ways to use technology to solve problems in everyday life and' +
          ' in the business world. The solution should be long term and sustainable, not merely a temporary fix.',
      },
      {
        name1: 'active',
        name2: 'lifestyle',
        description:
          'I believe that an active lifestyle makes one a better person. Thus, biking is my preferred mode ' +
          'transportation whenever possible. I also regularly lift weights. ' +
          'In addition, rock climbing, tennis,and basketball are also part of my workout every now and then.',
        pictures: [],
      },
      {
        name1: 'life worth',
        name2: 'living',
        description:
          "Plato's philosophy, 'The unexamined life is not worth living', really resonates with me. " +
          'Accordingly, I regularly reflect on my life in quest of a life truly worth living.  I would like to hear ' +
          'your ideas about it.',
      },
      {
        name1: 'the great',
        name2: 'outdoors',
        description:
          'I find solace in nature. I try to go hiking and backpacking when I can. My favorite trip ' +
          'has to be winter backpacking to Ostrander Hut.',
        pictures: [],
      },
      {
        name1: 'other',
        name2: 'interests',
        description:
          'I strive to engage with the world around me as much possible.  Some ways I try to accomplish that are ' +
          'volunteering, reading books, picking up new hobbies and skills.  Some skills I am ' +
          'currently working on are riding a unicycle and making cappuccino art.',
      },
      {
        name1: 'about this',
        name2: 'website',
        description:
          'This website is designed and built entirely on my own. The libraries used are Twitter Bootstrap, ' +
          'jQuery, and D3js.',
      },
    ];

    function randomXCoordinate() {
      return Math.round(Math.random() * (0.75 * xGoal));
    }

    function randomYCoordinate() {
      return Math.round(Math.random() * (height - radius * 2) + radius);
    }

    for (let i = 0; i < circles.length; i++) {
      let value = circles[i];
      value.x = randomXCoordinate();
      value.y = randomYCoordinate();
      circles[i] = value;
    }

    let color = d3.scaleOrdinal().range(d3.schemeCategory10);

    let group = svg
      .selectAll('g')
      .data(circles)
      .enter()
      .append('g')
      .attr('transform', function(d) {
        if (isNaN(d.y)) {
          d.y = radius + 20;
        }
        if (isNaN(d.x)) {
          d.x = radius + 20;
        }
        return 'translate(' + d.x + ', ' + d.y + ')';
      });

    group
      .append('circle')
      .attr('r', radius)
      .style('fill', function(d, i) {
        return color(i);
      });

    let circleText = group.append('text').attr('y', '-30');

    circleText
      .append('tspan')
      .text(function(d, i) {
        return d.name1;
      })
      .attr('dy', '1.4em')
      .attr('x', '0')
      .attr('text-anchor', 'middle');

    circleText
      .filter(function(d) {
        return 'name2' in d;
      })
      .append('tspan')
      .text(function(d) {
        return d.name2;
      })
      .attr('dy', '1.4em')
      .attr('x', '0')
      .attr('text-anchor', 'middle');

    group.call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

    let blinkingInterval;

    function dragstarted(d) {
      clearInterval(blinkingInterval);
      blinkingInterval = null;
      d3.select(this)
        .raise()
        .classed('active', true);
    }

    const rect = d3.select('#goal');

    function createBlinkingInterval() {
      let timer = setInterval(function() {
        rect.classed('part-opacity', true);
      }, 300);

      return timer;
    }

    function removeBlinkingInterval() {
      clearInterval(blinkingInterval);
      blinkingInterval = null;
    }

    function dragged(d) {
      let x = d3.event.x;
      let y = d3.event.y;

      if (x < minX) {
        x = minX;
      }
      if (x > maxX) {
        x = maxX;
      }
      if (y < minY) {
        y = minY;
      }
      if (y > maxY) {
        y = maxY;
      }

      d3.select(this).attr('transform', 'translate(' + x + ', ' + y + ')');

      if (x + radius > xGoal) {
        if (blinkingInterval == null) {
          blinkingInterval = createBlinkingInterval();
        }
      } else {
        removeBlinkingInterval();
        rect.classed('part-opacity', false);
      }
    }

    const description = d3.select('#about-description');
    const section = d3.select('#about-section');

    function dragended(d) {
      removeBlinkingInterval();
      d3.select(this).classed('active', false);

      let x = d3.event.x;
      if (x + radius > xGoal) {
        description
          .transition()
          .duration(1000)
          .ease(d3.easeLinear)
          .style('opacity', 1)
          .on('end', () => {
            description.html(d.description);
            description
              .transition()
              .duration(1000)
              .style('display', 'block');
          });

        section
          .transition()
          .duration(2000)
          .style('display', 'block');

        let randomX = randomXCoordinate();
        let randomY = randomYCoordinate();
        d3.select(this)
          .transition()
          .delay(500)
          .attr('transform', 'translate(' + randomX + ', ' + randomY + ')');
        rect.classed('part-opacity', false);
      }
    }
  });

  return (
    <>
      <div id="about-section" className="row pd-24">
        <div className="col-md-12 m-t-16">
          <p id="about-description" className="text-lg pd-s-16">
            something
          </p>
        </div>
      </div>
      <div className="alert alert-info m-b-4" role="alert">
        Move any of the circles to the blue area
      </div>
      <svg className="full-svg">
        <rect
          id="goal"
          x="70%"
          height="100%"
          width="30%"
          fill="rgba(7, 73, 152, 0.6)"
          opacity="1.0"
        ></rect>
      </svg>
    </>
  );
};

export default OldInteractiveAboutSection;
