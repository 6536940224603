import React from 'react'
import Helmet from 'react-helmet'

// INTERNALS
import Layout from '../components/layout'
import OldInteractiveAboutSection from '../components/OldInteractiveAboutSection/OldInteractiveAboutSection'

// ASSETS
import pic11 from '../assets/images/pic11.jpg'
import oldLogo from '../assets/images/old-logo.svg'

const Generic = props => (
  <Layout>
    <Helmet>
      <title>Portfolios</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Portfolios</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p>
            Batman displays trophies in his Batcave, Superman displays trophies
            in his Fortress of Solitude, and I have this page to show and
            remember how far I have come along.
          </p>
        </div>
      </section>
      <section id="two">
        <div className="inner">
          <h3>Old Logo</h3>
          <p>
            This is a logo I created when trying to play around with svg. This
            was probably done around 2016 or 2017.
          </p>
          <img src={oldLogo} width={600} alt="some-old-logo" />
        </div>
      </section>
      <section id="three">
        <div className="inner">
          <h3>Interactive About Section</h3>
          <p>
            An attempt at creating an interactive about section. Done around
            2016 or 2017. I used jQuery for that, so I had to translate it to to
            use React.
          </p>
          <OldInteractiveAboutSection />
        </div>
      </section>
    </div>
  </Layout>
)

export default Generic
